<template>
  <div class="warp">
    <div class="container">
      <div class="left">
        <div class="question-info">
          <V-Question :questions="questions"></V-Question>
          <div class="right-btns">
            <span class="del show-hand" @click="collectQuestion(WDCollect)">
              <span v-if="WDCollect <= 0">
                <i class="collect2-icon"></i>
                <span class="hover">收藏</span>
              </span>
              <span v-else-if="WDCollect > 0">
                <i class="collect1-icon"></i>
                <span class="hover">已收藏</span>
              </span>
            </span>
            <!-- <span class="invite show-hand" @click="inviteAnswer">
              <i class="invite-icon"></i>
              <span>邀请回答</span>
            </span> -->
          </div>
          <div class="tdchBtn">
            <span>温馨提示：智荟网为提问者提供邀请专家自动成团回答问题服务——团队撮合</span>
            <div class="tdchBtn_btn" @click="tapTdch">团队撮合</div>
          </div>
          <div class="ask-setinfo">
            <div class="setinfo">
              <span>悬赏设置: {{ rewardType }}</span>
              <span v-if="this.question.reward.type != 1"
                >金额:{{ question.reward.pay }}知识币</span
              >
              <span
                v-if="
                  this.question.reward.type != 1 &&
                    question.reward.endTime != 'NaN-0NaN-0NaN 0NaN:0NaN:0NaN'
                "
                >悬赏截止时间:{{ question.reward.endTime }}</span
              >
            </div>
            <div class="setinfo">
              <span>求解方式: {{ solveType }}</span>
              <span
                v-if="this.question.solve.type == 2"
                v-show="
                  question.solve.startTime != 'NaN-0NaN-0NaN 0NaN:0NaN:0NaN' ||
                    question.solve.endTime != 'NaN-0NaN-0NaN 0NaN:0NaN:0NaN'
                "
                >求解时间:{{ question.solve.startTime }} 至
                {{ question.solve.endTime }}
              </span>
              <span v-if="this.question.solve.type == 2"
                >地点：{{ question.solve.adress }}</span
              >
            </div>
            <div class="setinfo">
              <span>查看分成: {{ sharedType }}</span>
              <span v-if="this.question.shared.type != 1"
                >金额:{{ question.shared.pay }}知识币</span
              >
              <span v-if="this.question.shared.type != 1"
                >提问者的比例:{{ question.shared.release }}%</span
              >
              <span v-if="this.question.shared.type != 1"
                >被采纳者的比例:{{ question.shared.answer }}%</span
              >
            </div>
          </div>
          <div class="q-note-inform">
            <span class="del show-hand" @click="deleteQuestion">
              <i class="del-icon"></i>
              <span>删除</span>
            </span>
          </div>
        </div>
        <!-- <div class="app-noanswer" style="margin:10px 0">
          <div class="q-nofit-answer">
            <i class="answer-icon"></i>
            <span>暂未寻找到合适的答案</span>
          </div>
        </div> -->

        <div class="q-similarity">
          <div class="smil-label">
            <i style="font-size:24px;color:rgb(240,118,57);font-weight: bold;" class="el-icon-trophy"></i>
            <span>推荐专家</span>
          </div>
          <div class="tjzjList">
            <div class="tjzj" v-for="(item,i) of options" :key="i">
              <img :src="'http://www.izhihui.net/bak'+item.img" alt="" class="tjzjImg">
              <div class="tjzjRight">
                <div class="right_left">
                  <div>{{item.name}}</div>
                  <div>邻域：{{item.industry}}</div>
                  <div>{{`回答数:${item.wdAnswerNum}\xa0\xa0\xa0被采纳数:${item.wdAcceptNum}\xa0\xa0\xa0发布文章数:${item.articleNum}`}}</div>
                  <!-- <div>啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊</div>
                  <div>啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊</div> -->
                </div>
                <div class="right_btn" @click="tapYqHd(item)">
                  邀请回答
                </div>
              </div>
            </div>
          </div>

          <!-- <hr />
          <div class="smil-qustion">
            <div
              class="sq-one"
              v-for="oneSmil in smilQuestionList"
              :key="oneSmil.id"
              @click="gotoDetail(oneSmil.id)"
            >
              <div class="sq-question">
                <span>问题标题:</span> <span>{{ oneSmil.questionTitle }}</span>
              </div>
              <div class="sq-answer" v-if="oneSmil.questionContent">
                <span>问题描述:</span>
                <span
                  v-if="
                    oneSmil.questionContent != undefined &&
                      oneSmil.questionContent.length > 100
                  "
                  >{{ oneSmil.questionContent.substring(0, 100) + "..." }}</span
                >
                <span
                  v-if="
                    oneSmil.questionContent != undefined &&
                      oneSmil.questionContent.length <= 100
                  "
                  >{{ oneSmil.questionContent }}</span
                >
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="right">
        <div class="right_top">
          <span>等待解答</span>
          <i class="el-icon-refresh" style="cursor: pointer;" @click="detailList">换一换</i>
        </div>

        <div class="right_list" v-for="(item,i) of tjlist" :key="i">
          <p class="list_text">{{item.questionTitle}}</p>
          <p style="text-align:right;margin-top:0">
            <i class="el-icon-edit-outline" @click="tapIanswer(item.id)" style="cursor:pointer;color:rgb(0,132,255);">我来答</i>
          </p>
        </div>
      </div>
    </div>
    <V-InviteAnswer
      ref="yaoqing"
      :isClose="isClose"
      :id="question.id"
      @closeInviteAnswer="closeThis"
    ></V-InviteAnswer>
  </div>
</template>

<script>
import Question from "@/components/question/index.vue";
import axios from "axios";
import InviteAnswer from "@/views/wisdom-answer/common/invite-answer/index.vue";
import { DELETE, QUERY, UPDATE, INSERT, QUERYED } from "../../../services/dao";
import {post} from "../../../services/http.js";
import { dataFormat, formatDate, showTimeFormat } from "@/js/common/index.js";
export default {
  data() {
    return {
      message: [],
      isClose: false,
      smilQuestionList: [],
      questions: [], //问题详情
      WDCollect: 0, //收藏状态
      question: {
        questioner: -1, //问题回答者
        id: "",
        title: "", //"算法正则化与拉格朗日乘子法有什么联系？",
        describe: "", //"算法"
        file: [
          {
            url: "",
            type: "",
          },
        ],
        shared: {
          type: 1, //分成 1-免费 2-分成
          pay: 0,
          answer: 0,
          release: 0,
        },
        reward: {
          type: 1, //悬赏类型 1-免费 2-竞价成交 3-抢答成交 4-信用度成交 5选择成交
          pay: 1,
          endTime: "", //'2020-04-20 12:00:00'
        },
        solve: {
          type: 1,
          startTime: "", //'2020-04-20 12:00:00',
          endTime: "", //'2020-04-20 12:00:00',
          adress: "",
        },
      },

      tjlist:[],
      options:[],  //推荐专家
    };
  },
  created() {
    this.getZjList()
    this.up();
  },
  mounted() {
    let num = 0;
    let id = this.$route.query.id;
    axios({
      method: "post",
      url: this.api.LoginURL.concat("/common/similarQuestion"),
      params: {
        id: id,
        raw: 10000, //行数
        page: 1, //页数
        money: -1, //分成
        competePriceSetting: -1, //悬赏
      },
    })
      .then((response) => {
        for (let i = 0; i < response.data.data.data.length; i++) {
          if (i < 20) {
            this.smilQuestionList.push({
              id: response.data.data.data[i].id,
              questionTitle: response.data.data.data[i].questionTitle,
              questionContent: response.data.data.data[i].questionContent,
            });
          }
        }
      })
      .catch(function(error) {
        console.log("错误" + error);
      });
    // }
  },
  computed: {
    mediaList: function() {
      let file = [];
      this.file.forEach((item, index) => {
        if (item.type == "video/mp4") {
          let url = URL.createObjectURL(item);
          file.push({ type: "video", src: url, name: item.name });
        }
        if (item.type == "image/jpeg") {
          let url = URL.createObjectURL(item);
          file.push({ type: "img", src: url, name: item.name });
        }
      });
      return file;
    },
    rewardType: function() {
      if (this.question.reward.type == 1) {
        return "免费";
      }
      if (this.question.reward.type == 2) {
        return "竞价成交";
      }
      if (this.question.reward.type == 3) {
        return "抢答成交";
      }

      if (this.question.reward.type == 4) {
        return "信用度成交";
      }
      if (this.question.reward.type == 5) {
        return "选择成交";
      }
      return "";
    },
    solveType: function() {
      if (this.question.solve.type == 1) {
        return "线上";
      }
      if (this.question.solve.type == 2) {
        return "线下";
      }
      return "";
    },
    sharedType: function() {
      if (this.question.shared.type == 1) {
        return "免费";
      }
      if (this.question.shared.type == 2) {
        return "分成";
      }
      return "";
    },
  },
  methods: {
    //获取专家
    getZjList(){
      let fontData = new FormData()
      fontData.append('industry',this.$route.query.teamTypes)
      post('/common/tjzj',fontData).then(res=>{
        console.log(res.data);
        this.options = res.data
      })
    },

    up() {
      this.question.id = this.$route.query.id;
      this.details(); //问题详情
      this.detailList();  //推荐问题列表
      this.getDetail();
      this.Collection(); //显示收藏状态
    },
    async sendMessage() {
      if (this.message.length > 0) {
        let info = await UPDATE(
          "POST",
          "",
          "update_WdQuestion(_set: {isSendMsg:1}, where: {	id: {		_eq: " +
            this.question.id +
            "	}}) {	affected_rows returning {		id	}}"
        );
        if (!info.data.update_WdQuestion.affected_rows) {
          this.message.length = 0;
        }
      } else {
        let info = await UPDATE(
          "POST",
          "",
          "update_WdQuestion(_set: {isSendMsg:0}, where: {	id: {		_eq: " +
            this.question.id +
            "	}}) {	affected_rows returning {		id	}}"
        );
        if (!info.data.update_WdQuestion.affected_rows) {
          this.message.length = 1;
        }
      }
    },

    //推荐问题列表
    async detailList(){
      this.tjlist = []
      
      let count = await QUERYED(
        "post",
        "",
        "WDNewQuestion_aggregate(where: {problemClassification: {_eq: \"" +
          this.$route.query.teamTypes +
          "\"}}) {  aggregate {   count  }}"
      );
      if(count.success){
        let sql = 'null'
        let num = count.data.WDNewQuestion_aggregate.aggregate.count
        if(num<=20){
          sql = `WDNewQuestion(where:{problemClassification:{_eq:"${this.$route.query.teamTypes}"}}) {id questionTitle}`
        }else{
          num = parseInt(num/20)
          let ran = parseInt(Math.random()*num+num)*20
          sql = `WDNewQuestion(limit:20,offset:${ran},where:{problemClassification:{_eq:"${this.$route.query.teamTypes}"}}) {id questionTitle}`
        }
        let data = await QUERYED("post","",sql);
        if(data.success){
          // setTimeout(() => {
            this.tjlist = data.data.WDNewQuestion
          // }, 300);
        }
      }
    },

    //我来答
    tapIanswer(id){
      this.$router.push({path:'/writeanswer?id='+id})
    },

    //邀请回答
    async tapYqHd(item){
      let token =  localStorage.getItem("Authorization");
      //获取登陆用户ID
      let userId = this.$store.state.userinfo.id;
      if (!token||!userId) {
        this.$message({
          message: '请先登录！',
          type: 'warning'
        });
        this.$router.push({ path: "/login" });
        return
      }
      let getyq = await QUERYED(
        "post","",
        `WdInvitation_aggregate(where:{user:{_eq:${userId}},userid:{_eq:${item.id}},questioner:{_eq:${this.$route.query.id}}}) {aggregate {count}}`
      )
      if(getyq.success){
        if(getyq.data.WdInvitation_aggregate.aggregate.count<=0){
          let setYq = await INSERT(
            "post",
            "",
            `insert_WdInvitation(objects:{user:"${userId}", userid:"${item.id}", createtime:"${new Date()}",questioner:${this.$route.query.id}}) {affected_rows returning {id}}`
          );
          if(setYq.success){
            let yqId = setYq.data.insert_WdInvitation.returning[0].id
            await INSERT(
              "post",
              "",
              "insert_MessageNotification(objects:{type:1,invitationid:" +
                yqId +
                ', creatime: "' +
                new Date() +
                '",state:1, userid: ' +
                item.id +
                "}) {affected_rows  }"
            );
            this.$message({
              message: '邀请成功',
              type: 'success'
            });
          }
        }else{
          this.$message({
            message: '该专家已经邀请过了',
            type: 'warning'
          });
        }
      }
    },


    // 邀请回答
    async tapYqHd1(id, index, e) {
      let datas = null;
      let token =  localStorage.getItem("Authorization");
      //获取登陆用户ID
      let userId = this.$store.state.userinfo.id;
      if (!token||!userId) {
        this.$message({
          message: '请先登录！',
          type: 'warning'
        });
        this.$router.push({ path: "/login" });
        return
      } else {
        // 已邀请过的用户不得再次邀请
        // if (e.target.innerText != "已邀请") {
          datas = await INSERT(
            "post",
            "",
            "   insert_WdInvitation(objects: {user: " +
              this.user +
              ", userid: " +
              id +
              ', createtime: "' +
              new Date() +
              '",questioner:' +
              question +
              "}) { affected_rows     returning {    id  } }"
          );
          if (datas.data.insert_WdInvitation.affected_rows) {
            //   消息表发送一条消息
            let modesl = await INSERT(
              "post",
              "",
              "   insert_MessageNotification(objects: {type: 1, invitationid: " +
                datas.data.insert_WdInvitation.returning[0].id +
                ', creatime: "' +
                new Date() +
                '",state:1, userid: ' +
                id +
                "}) {affected_rows  }"
            );
            if (modesl.data.insert_MessageNotification.affected_rows == 1) {
              e.target.innerText = "已邀请";
              // this.invitation=1;
            }
          }
        // }
      }
    },

    //显示问题详情
    async details() {
      let data = await QUERYED(
        "post",
        "",
        "  WDNewQuestion(where: {id: {_eq: " +
          this.question.id +
          "}}) { id questionTitle  questionContent name  img  problemClassification video isViewFree keyword createTime viewPrice  answerAdress  answerStartTime  answerEndTime  answerWay  competeDeadlineTime  competeMode competePriceSetting}"
      );
      this.questions.splice(0, this.questions.length);
      for (let i = 0; i < data.data.WDNewQuestion.length; i++) {
        this.questions.push({
          id: data.data.WDNewQuestion[i].id,
          name: data.data.WDNewQuestion[i].name,
          questionTitle: data.data.WDNewQuestion[i].questionTitle,
          describe: data.data.WDNewQuestion[i].questionContent,
          img: this.api.LoginURL.concat(data.data.WDNewQuestion[i].img),
          video: this.api.LoginURL.concat(data.data.WDNewQuestion[i].video),
          createTime: showTimeFormat(data.data.WDNewQuestion[i].createTime),
          keywords: data.data.WDNewQuestion[i].keyword,
          competePriceSetting: data.data.WDNewQuestion[i].competePriceSetting,
          teamTypes: data.data.WDNewQuestion[i].problemClassification,
          problemClassification:
            data.data.WDNewQuestion[i].problemClassification,
        });
      }
    },

    //删除
    async deleteQuestion() {
      let info = await DELETE(
        "post",
        "",
        " delete_WdQuestion(where: {id: {_eq: " +
          this.question.id +
          "}}) {     affected_rows   }"
      );
      if (
        info.data.delete_WdQuestion &&
        info.data.delete_WdQuestion.affected_rows > 0
      ) {
        this.$message({
          type: "warning",
          message: "删除成功！",
        });
        this.$router.push("/qaIndex");
      } else {
        this.$message({
          type: "warning",
          message: "删除失败！",
        });
      }
    },
    modifyQuestion() {
      this.$router.push({
        path: "/askQuestion",
        query: {
          id: this.question.id,
        },
      });
    },
    downLoadApp() {
      this.$message({
        message: '下载APP',
        type: 'success'
      });
      
    },
    shareQuestion() {
      this.$message({
        message: '分享问题',
        type: 'success'
      });
    },

    ///显示收藏状态
    async Collection() {
      //获取问题的ID
      this.problemid = this.$route.query.id;
      //获取登陆用户ID
      let user = null;
      this.user = this.$store.state.userinfo.id;
      if (
        this.user == "" ||
        this.user == null ||
        this.user == "Undefined" ||
        this.user < 0
      ) {
        this.WDCollect = 0;
      } else {
        //查询收藏的ID是否存在
        let totals = await QUERYED(
          "post",
          "",
          "    WDCollect(where: {user: {_eq: " +
            this.user +
            "}, question: {_eq: " +
            this.problemid +
            "}}) { id }"
        );
        //alert(totals.data.WDCollect.length)
        this.WDCollect = totals.data.WDCollect.length;
      }
    },
    //收藏问题
    async collectQuestion(WDCollect) {
      //添加
      let datas = null;
      //删除
      let datad = null;
      //收藏ID
      let datdse = null;
      //获取问题的ID
      this.problemid = this.$route.query.id;
      //获取登陆用户ID
      let user = null;
      this.user = this.$store.state.userinfo.id;
      if (this.user == null || this.user == "" || this.user < 0) {
        this.$message({
          type: "warning",
          message: "您还未登录，请您先登录！",
        });
      } else {
        // 获取问题表里面的收藏数
        datdse = await QUERYED(
          "post",
          "",
          "  WDQuestion(where: {id: {_eq: " +
            this.problemid +
            "}}) {  collectNum  }"
        );
        //alert(datdse.data.WDQuestion[0].collectNum)
        //未收藏状态
        if (WDCollect <= 0) {
          datas = await INSERT(
            "post",
            "",
            "   insert_WdCollect(objects: {question: " +
              this.problemid +
              ", user: " +
              this.user +
              ', collectDateTime: "' +
              new Date() +
              '"}) { affected_rows  }'
          );
          if (datas.data.insert_WdCollect.affected_rows > 0) {
            datdse.data.WDQuestion[0].collectNum += 1;
            let datdeds = await UPDATE(
              "post",
              "",
              "   update_WdQuestion(where: {id: {_eq: " +
                this.problemid +
                "}}, _set: {collectNum: " +
                datdse.data.WDQuestion[0].collectNum +
                "}) { affected_rows  }"
            );
            if (datdeds.data.update_WdQuestion.affected_rows > 0) {
              console.log(this.datas);
              //更新显示收藏状态
              this.Collection();
            }
          }
        } else {
          datad = await DELETE(
            "post",
            "",
            "  delete_WdCollect(where: {user: {_eq: " +
              this.user +
              "}, question: {_eq: " +
              this.problemid +
              "}}) {affected_rows  }"
          );
          //更新显示收藏状态
          this.Collection();
          if (datad.data.delete_WdCollect.affected_rows > 0) {
            if (datdse.data.WDQuestion[0].collectNum == 0) {
              datdse.data.WDQuestion[0].collectNum == 0;
            } else {
              datdse.data.WDQuestion[0].collectNum -= 1;
            }
          }
        }
      }
    },
    closeThis() {
      this.isClose = false;
    },
    inviteAnswer() {
      this.isClose = true;
      this.$refs.yaoqing.$emit("bridge");
    },
    ///显示悬赏类别
    async getDetail() {
      //通过id 查询 数据
      let info = await QUERYED(
        "post",
        "",
        "WDQuestion(where: {id: {_eq: " +
          this.$route.query.id +
          "}}) {createTime questioner  answerAdress   answerDeadlineTime     answerEndTime     answerStartTime     answerWay     answererPercentage     competeDeadlineTime     competeMode     competePriceSetting     keyword     mediaPathArray     questionContent     questionTitle     questionerPercentage 	isViewFree   isSendMsg  viewPrice   }"
      );
      for (let i = 0; i < info.data.WDQuestion.length; i++) {
        this.question.solve.endTime = showTimeFormat(
          info.data.WDQuestion[i].answerEndTime
        );
        this.question.solve.startTime = showTimeFormat(
          info.data.WDQuestion[i].answerStartTime
        );
        this.question.solve.type = info.data.WDQuestion[i].answerWay;
        this.question.solve.adress = info.data.WDQuestion[i].answerAdress;
        this.question.reward.endTime = showTimeFormat(
          info.data.WDQuestion[i].competeDeadlineTime
        );
        this.question.reward.type = info.data.WDQuestion[i].competeMode;
        this.question.reward.pay = info.data.WDQuestion[i].competePriceSetting;
        this.question.shared.type = info.data.WDQuestion[i].isViewFree;
        this.question.shared.pay = info.data.WDQuestion[i].viewPrice;
        this.question.shared.answer =
          info.data.WDQuestion[i].answererPercentage;
        this.question.shared.release =
          info.data.WDQuestion[i].questionerPercentage;
        this.question.questioner = info.data.WDQuestion[i].questioner;
      }
    },
    async gotoDetail(id) {
      let info = await QUERYED(
        "post",
        "",
        "  WDNewQuestion(where: {id: {_eq: " + id + "}}) { scanNum  }"
      );
      let anser = 0;
      this.anser = info.data.WDNewQuestion[0].scanNum;
      this.anser += 1;
      let dataed = await UPDATE(
        "post",
        "",
        "   update_WdQuestion(where: {id: {_eq: " +
          id +
          "}}, _set: {scanNum: " +
          this.anser +
          "}) { affected_rows  }"
      );
      if (dataed.data.update_WdQuestion.affected_rows > 0) {
        this.$router.push({
          path: "/writeanswer",
          query: {
            id: id,
          },
        });
      }
    },

    //撮合团队
    tapTdch(){
      this.$router.push({path:'/create-a-team',query:{type:1,problemClassification:this.questions[0].problemClassification,issueId:this.$route.query.id}})
    },
  },
  components: {
    "V-Question": Question,
    "V-InviteAnswer": InviteAnswer,
  },
};
</script>

<style scoped>
.container {
  width: 1000px;
  margin: 0 auto;
  display: flex;
}
.collect2-icon {
  width: 14px;
  height: 14px;
  display: inline-block;
  background-image: url("../../../assets/icon/Collection.png");
  background-size: 14px 14px;
  background-repeat: no-repeat;
  position: relative;
  left: 3px;
  margin-right: 5px;
}
.hover:hover {
  color: #0084ff;
}
/* //收藏按钮 */
.collect1-icon {
  width: 14px;
  height: 14px;
  display: inline-block;
  background-image: url("../../../assets/icon/Collectioned1.png");
  background-size: 14px 14px;
  background-repeat: no-repeat;
  position: relative;
  left: 3px;
  margin-right: 5px;
}
.container .left {
  width: 750px;
}

.question-info,
.app-noanswer,
.q-similarity {
  background-color: #fff;
}
.question-info {
  padding: 50px 30px 0 30px;
}
.app-noanswer {
  padding: 10px 30px;
}
.q-similarity {
  padding: 10px 30px;
}
.keyword-block {
  padding: 20px 0;
}
.q-btns {
  position: relative;
}
.q-btns * {
  font-size: 12px;
  font-weight: 300;
  color: rgba(118, 118, 118, 1);
  vertical-align: middle;
}
.q-btns .keyword-icon {
  margin-left: 20px;
}
.word {
  margin-left: 5px;
  margin-top: 10px;
}

.question-info .right-btns {
  text-align: right;
}
.question-info .right-btns > span {
  margin-left: 20px;
}
.question-info .ask-setinfo {
  margin-top: 20px;
  min-height: 80px;
  background-color: #f0f1f1;
  padding: 5px;
}
.setinfo > span {
  margin-right: 5px;
}
.ask-setinfo > div {
  height: 25px;
  line-height: 25px;
  font-size: 11px;
}
.q-btns .right-btns > span {
  margin-left: 10px;
}
.q-btns .right-btns > span > * {
  vertical-align: middle;
  margin-left: 5px;
}
.share:hover {
  color: #0084ff;
}
.share-icon {
  display: inline-block;
  width: 13px;
  height: 13px;
  background-repeat: no-repeat;
  background-image: url("~@/assets/icon/share.png");
  background-size: 10px 10px;
  position: relative;
  top: 3px;
}
.invite-icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-repeat: no-repeat;
  background-image: url("~@/assets/icon/invite.png");
  background-size: 14px 14px;
  position: relative;
  top: 3px;
  left: -3px;
}
.left .q-note-inform {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 300;
  color: rgba(118, 118, 118, 1);
  padding-bottom: 22px;
}
.q-note-inform .showtellphone {
  float: right;
}
.q-note-inform .modify {
  margin-left: 20px;
}
.q-app {
  text-align: right;
  font-size: 14px;
  font-weight: 300;
  margin: 20px 0;
}
.q-app > * {
  vertical-align: middle;
}
.q-app span {
  color: #0084ff;
}
.invite:hover {
  color: #0084ff;
}
.tellphone-icon {
  display: inline-block;
  width: 12px;
  height: 16px;
  background-repeat: no-repeat;
  background-image: url(~@/assets/icon/tellphone-icon.png);
}
.q-nofit-answer {
  padding: 20px 10px;
  background-color: rgba(248, 249, 250, 1);
  margin-bottom: 10px;
}
.answer-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-image: url(~@/assets/icon/answer-icon.png);
}
.q-nofit-answer > span {
  margin-left: 10px;
  font-size: 14px;
}
.q-similarity {
  padding-top: 30px;
  padding-bottom: 80px;
}
.smil-label {
  padding: 20px 0;
  border-bottom: 1px solid #ccc;
}
.smil-label > * {
  vertical-align: middle;
}
.smil-label span {
  padding-left: 10px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(112, 112, 112, 1);
}
.similar-icon {
  display: inline-block;
  width: 18px;
  height: 16px;
  background-repeat: no-repeat;
  background-image: url(~@/assets/icon/similar-icon.png);
}
.q-similarity .smil-qustion {
  padding-top: 10px;
}
.app-noanswer {
  background-color: #fff;
}
.sq-one {
  margin-bottom: 10px;
}
.sq-one > div {
  padding: 5px 0;
}
.sq-one .sq-answer > :first-child {
  color: #0084ff;
}
.more-smil-question {
  color: #767676;
  font-size: 14px;
  cursor: pointer;
}
.show-hand {
  cursor: pointer;
  font-size: 12px;
}
.sq-one {
  cursor: pointer;
}
.sq-one:hover {
  box-shadow: 2px 2px 5px #bbb;
  background-color: #eeebeb;
  border: 1px solid #f5f0f0;
  padding: 10px;
}
.sq-question {
  font-size: 14px;
  line-height: 18px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  word-wrap: break-word;
}
.sq-answer {
  font-size: 12px;
  line-height: 18px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  word-wrap: break-word;
}
.tdchBtn{
  width: 98%;
  margin-top:10px;
  padding:10px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border:1px solid rgb(0,132,255);
}
.tdchBtn_btn{
  width: 100px;
  height: 30px;
  border-radius: 10px;
  border:1px solid rgba(0,132,255,0.5);
  background-color: rgba(0,132,255,0.2);
  text-align: center;
  line-height: 30px;
  color: rgba(0,132,255,0.7);
  cursor: pointer;
}
.tdchBtn_btn:hover{
  opacity: 0.9;
}

.container .right {
  width: 250px;
  /* height: auto; */
  margin-left: 15px;
  padding:25px 15px;
  background-color: #fff;
}
.right_top{
  width: 100%;
  display: flex;
  padding-bottom: 5px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
}
.right_list{
  margin-top: 5px;
  border-bottom: 1px solid #eee;
}
.list_text{
  width: 100%;
  margin: 0;
  font-size: 14px;
  color: #aaa;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tjzjList{
  /* height: 700px; */
  /* background-color: khaki; */
  /* border-top: 1px solid #ccc; */
}
.tjzj{
  margin-top: 20px;
  display: flex;
  
}
.tjzjImg{
  width: 80px;
  height:80px;
}
.tjzjRight{
  flex: 1;
  margin-left: 10px;
  border-bottom: 1px solid #bbb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.right_left{
  flex: 1;

}
.right_btn{
  width: 70px;
  height: 30px;
  border-radius: 10px;
  border:1px solid rgba(0,132,255,0.5);
  background-color: rgba(0,132,255,0.2);
  text-align: center;
  line-height: 30px;
  color: rgba(0,132,255,0.7);
  cursor: pointer;
}
.right_left>div{
  width: 480px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.right_left>div:first-child{
  font-weight: bold;
}
.right_left>div:last-child{
  opacity: 0.8;
}
.right_left>div+div{
  margin-top: 5px;
}
</style>
